@charset "utf-8";

@font-face {
    font-family: 'CinzelDecorative';
    src: url('./assets/fonts/CinzelDecorative/CinzelDecorative-Regular.woff2') format('woff2'),
        url('./assets/fonts/CinzelDecorative/CinzelDecorative-Regular.woff') format('woff');
    font-weight: normal;
    font-style: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./assets/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
        url('./assets/fonts/SourceSansPro/SourceSansPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./assets/fonts/SourceSansPro/SourceSansPro-Light.woff2') format('woff2'),
        url('./assets/fonts/SourceSansPro/SourceSansPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./assets/fonts/SourceSansPro/SourceSansPro-Black.woff2') format('woff2'),
        url('./assets/fonts/SourceSansPro/SourceSansPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'vr-iconfont';
    src: url('./assets/fonts/vr-iconfont/vr-iconfont.eot?73013577');
    src: url('./assets/fonts/vr-iconfont/vr-iconfont.eot?73013577#iefix') format('embedded-opentype'),
        url('./assets/fonts/vr-iconfont/vr-iconfont.woff2?73013577') format('woff2'),
        url('./assets/fonts/vr-iconfont/vr-iconfont.woff?73013577') format('woff'),
        url('./assets/fonts/vr-iconfont/vr-iconfont.ttf?73013577') format('truetype'),
        url('./assets/fonts/vr-iconfont/vr-iconfont.svg?73013577#vr-iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "vr-iconfont";
    font-style: normal;
    font-weight: normal;
    speak: never;
}

.icon-down:before {
    content: '\e800';
}

.icon-check:before {
    content: '\e801';
}

.icon-logout:before {
    content: '\e802';
}

.icon-left-circle:before {
    content: '\e803';
}

.icon-cancel-circled:before {
    content: '\e804';
}

.icon-plus-circled:before {
    content: '\e805';
}

.icon-upload:before {
    content: '\e806';
}

.icon-pdf:before {
    content: '\e807';
}

.icon-info:before {
    content: '\e808';
}

.icon-cancel:before {
    content: '\e809';
}

.icon-pencil:before {
    content: '\e80a';
}

.icon-right:before {
    content: '\e80b';
}

.icon-left:before {
    content: '\e80c';
}

.icon-up:before {
    content: '\e80d';
}

.icon-chatbubbles:before {
    content: '\e80e';
}

.icon-person-pin:before {
    content: '\e80f';
}

.icon-paw:before {
    content: '\e810';
}

.icon-bone:before {
    content: '\e811';
}

.icon-send:before {
    content: '\e812';
}

.icon-new-chat:before {
    content: '\e813';
}

.icon-mailbox:before {
    content: '\e814';
}

.icon-user-plus:before {
    content: '\e815';
}

.icon-menu:before {
    content: '\f008';
}

.icon-download:before {
    content: '\f0ed';
}

.icon-instagram:before {
    content: '\f16d';
}

.icon-facebook:before {
    content: '\f230';
}

.error {
    display: block;
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 3px;
    color: red;
}

.messages .error:before {
    display: inline-block;
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='red' class='bi bi-exclamation-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: red;
    vertical-align: middle;
}

.success {
    display: block;
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 3px;
    color: #3d9e3d;
}

.messages .success:before {
    display: inline-block;
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='green' class='fas fa-check' viewBox='0 0 512 512'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: #3d9e3d;
    vertical-align: middle;
}

/*Allgemein*/
html, body {
    scroll-behavior: smooth;
}

body {
    font-size: 1.2rem;
    width: 100vw;
    line-height: 1.3;
    overflow-x: hidden;
    margin: auto;
    font-weight: 300;
    position: relative;
    font-family: 'SourceSansPro', sans-serif;
    color: var(--text-dark);
}

#logo img {
    display: inline-block;
    width: 45px;
    height: auto;
}

main {
    max-width: 1920px;
    overflow-x: hidden;
}

/*Headlines und Text*/
h1 {
    font-weight: 600;
    font-size: 3rem;
}

h1.vet-headline {
    font-family: 'CinzelDecorative' !important;
    font-weight: 500 !important;
}

h2, h3 {
    font-weight: 700;
}

h4 {
    font-size: 1.2rem;
}

p {
    line-height: 1.45;
}

h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
}

h2.font-green {
    color: #02bec4;
    text-transform: uppercase;
    font-size: 1.5rem;
}

a {
    text-decoration: none;
}

.font-bezeichnung {
    font-weight: 600;
}

.searchbar .form-control {
    width: 31%;
}

/*FAQ*/
button.accordion-button {
    background-color: #efefef;
}

.accordion-body {
    border: 2px solid #AEAEAE;
}

.accordion-flush .accordion-item .accordion-button {
    font-size: 1.1rem;
}

.color-primary, .simple-green {
    color: #02bec4;
}

.accordion-button:link, .accordion-button:visited, .accordion-button:hover, .accordion-button:active {
    background-color: #efefef;
    color: #02bec4;
}

button.accordion-button:focus {
    border: none;
    box-shadow: none;
}

/*Tabellen*/
.table .table-title {
    color: #02bec4;
    font-weight: 600;
}

.table td {
    white-space: nowrap;
}

.table .table-middle {
    vertical-align: middle;
}

.table .table-link {
    color: #111921;
    font-weight: 500;
    font-size: 1rem;
    white-space: nowrap;
}

.table .table-link svg[data-prefix=fas]{
    color: #02bec4;
    font-size: 1.02rem;
}

.table>:not(caption)>*>* {
    border-bottom: 0;
}

.table>:not(:first-child) {
    border-top: 0;
}

/*Links und Buttons*/
button.logout:hover {
    color: #02bec4;
}

a.white-rounded-link, button.btn-white {
    background-color: #fff;
    color: #111921 !important;
    font-weight: 600;
    border: none;
}

.filter-options input+label:hover,
.filter-options input+label:focus,
.btn-grey:hover, .btn-grey:focus, a.white-rounded-link:hover, a.grey-rounded-link:hover, a.grey-rounded-link:focus, a.white-rounded-link:focus, button.btn-white:hover, button.btn-white:focus, button.btn-green:hover, button.btn-green:focus, a.green-rounded-link:hover, a.green-rounded-link:focus {
    background-color: #111921;
    color: #fff !important;
}

.filter-options input+label,
button.btn-green, a.green-rounded-link {
    background-color: #02bec4;
    color: #fff;
    border: none;
}

a.grey-rounded-link {
    background-color: #AEAEAE;
    color: #fff;
}

.btn-grey {
    background-color: #AEAEAE;
    color: #fff;
    border: none;
}

button.btn-green a {
    color: #fff;
}

.bg-grey a, .btn-grey a {
    color: #fff;
}

.bg-grey a:hover, .bg-grey:focus {
    color: #fff;
}

.simple-green:hover, .simple-green:focus {
    color: #02bec4;
    text-decoration: underline;
}

.link-upload-cancel {
    background: #AEAEAE;
    color: #fff;
    border-radius: 5px;
}

.link-upload {
    border-radius: 5px;
}

.i-left::before {
    padding-left: 0.5rem;
}

.i-right::before {
    padding-right: 0.5rem;
}

.user {
    color: #111921;
    font-weight: 600;
    text-transform: uppercase;
}

.user i::before {
    color: #02bec4;
    font-size: 1.5rem;
    vertical-align: middle;
}

.user:hover{
    color: #02bec4;
}

button.logout {
    border: 1px solid #111921;
    border-radius: 5px;
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #111921;
}

button.logout i {
    color: #02bec4;
}

.social-contact a {
    font-size: 1.7rem;
    padding-right: 0.7rem;
}

.social-contact a:hover, .social-contact a:focus {
    text-decoration: none;
}

.accordion-button::after {
    background-image: url(./assets/images/download.svg) !important;
    transform: rotate(270deg);
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(360deg);
}

.form-select {
    background-image: url(./assets/images/download.svg);
    background-size: 16px 16px;
}

/* select.form-select {
    color: #AEAEAE;
} */

::placeholder {
    color: #AEAEAE;
}

.link-upload-cancel a {
    right: 1rem;
    color: #fff;
}

/*Progressbar Mein Bereich*/
.progress {
    background-color: #667D91;
    font-size: 1rem;
    height: 1.3rem;
}

.progress-bar {
    text-align: left;
    padding-left: 1rem;
}

.progress-offen, .bg-red {
    background-color: #FB291A;
}

.progress-abgeschlossen, .bg-badge-green {
    background-color: #4BD63C;
    color: #111921;
    font-weight: 500;
}

.progress-befund, .bg-orange {
    background-color: #FB8B1A;
}

.progress-bearbeitung, .bg-yellow {
    background-color: #eec306;
    color: #111921;
    font-weight: 500;
}

/*Hauptnavigation*/
#hnav ul li {
    list-style-type: none;
    display: inline-block;
    padding-right: 3.5rem;
    font-weight: 700;
}

#hnav ul li a.active {
    color: #02bec4;
}

#hnav ul li a {
    text-decoration: none;
    color: #111921;
}

#hnav ul li a:hover, #hnav ul li a:focus {
    color: #02bec4;
}

#navarea, #navarea-small {
    padding-top: 0.6rem;
}

#hnav ul {
    padding: 0;
    margin: 0;
}

label {
    color: #111921;
    font-weight: 400;
}

.font-black {
    color: #111921 !important;
}

#show {
    display: none;
}

/*Hintergründe und Bilder*/
.bg-green {
    background: #02bec4;
    color: #fff;
}

.bg-grey, .bg-grey-left, .bg-grey-right {
    background: #667D91;
    color: #fff;
}

.bg-lightgrey {
    background-color: #EEF0F2;
}

.bg-grey-left {
    margin-right: 0.8rem;
    height: 100%;
    border-radius: 0 15px 15px 0;
}

.bg-grey-right {
    margin-left: 0.8rem;
    height: 100%;
    border-radius: 15px 0 0 15px;
}

.bg-footer {
    background-image: url(./assets/images/bg-footer.png);
    background-repeat: no-repeat;
    background-position: -12rem 4rem;
    background-size: 56rem;
    padding-top: 24rem;
}

.border-rounded {
    border-radius: 15px;
}

.square-grey {
    width: 100%;
    height: 12rem;
    z-index: -10;
    left: 0
}

.square-grey-left {
    width: 45%;
    height: 23rem;
    z-index: -10;
    left: 0;
}

footer a {
    color: #fff;
}

footer a:hover, footer a:focus {
    color: #fff;
    text-decoration: underline;
}

.katzen-img img {
    width: 800px;
    height: auto;
    position: absolute;
    bottom: -7rem;
    right: -4rem;
}

.dog-pic {
    left: -17rem;
    bottom: -18rem;
    opacity: 0.8;
}

.dog-pic img {
    width: 1000px;
    height: auto;
}

.us-katze img {
    width: 400px;
    height: auto;
    position: absolute;
    top: -8.5rem;
}

.info-founder img {
    width: 220px;
    height: auto;
}

.info-founder, .info-founder-text {
    display: inline-block;
}

.info-founder-text h4 {
    font-weight: 700;
    color: #667D91;
}

.info-founder-text p {
    font-weight: 500;
}

.info-founder-text p a {
    color: #111921;
}

.info-founder-text p a:hover, .info-founder-text p a:focus {
    color: #02bec4;
}

.border-top-green {
    border-top: #02bec4 solid 1px;
}

/*Topscroller*/
#topscroller {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    background: #667D91;
    height: 3.5rem;
    width: 3.5rem;
    z-index: 200;
    display: none;
    border: 1px solid #ffffff;
}

#topscroller a {
    color: #667D91;
    font-size: 2.4rem;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    line-height: 3.3rem;
}

#topscroller i {
    display: inline-block;
    color: #fff;
}

/*Tabs*/
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    border-radius: 0;
    background: #aceaec;
    border-bottom: 2px solid #02bec4 !important;
}

.nav-link {
    color: #111921 !important;
}

.user-recieve {
    font-weight: 500;
    font-size: 1rem;
}

.message-info {
    font-weight: 500;
}

.nav-link .icon-mailbox {
    color: #FB291A;
}

/*Progressbar Anfrageformular*/
#progressbar .active {
    color: #02bec4;
}

#progressbar li {
    list-style-type: none;
    font-size: 0.9rem;
    text-align: center;
    width: 20%;
    display: inline-block;
    position: relative;
    font-weight: 400;
    color: #AEAEAE;
}

#progressbar #step01:before {
    content: "1";
    text-align: center;
    font-size: 2.2rem;
    line-height: 1.25;
}

#progressbar #step02:before {
    content: "2";
    text-align: center;
    font-size: 2.2rem;
    color: #AEAEAE;
    line-height: 1.25;
}

#progressbar #step03:before {
    content: "3";
    text-align: center;
    font-size: 2.2rem;
    color: #AEAEAE;
    line-height: 1.25;
}

#progressbar #step04:before {
    content: "4";
    text-align: center;
    font-size: 2.2rem;
    color: #AEAEAE;
    line-height: 1.25;
}

#progressbar #step05:before {
    content: "\e801";
    font-family: 'vr-iconfont';
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.6;
    color: #AEAEAE;
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    display: block;
    font-size: 0.9rem;
    background: #EEF0F2;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 7px;
    background: #EEF0F2;
    position: absolute;
    left: 0;
    top: 22px;
    z-index: -1;
}

#progressbar li.active:before, #progressbar li.active:after {
    background: #02bec4;
    color: #fff !important;
}

/*Anfragen Steps Icons*/
.step01 i.icon-paw, .step02 i.icon-bone, .step03 i.icon-info, .step04 i.icon-upload {
    background: #02bec4;
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: inline-block;
}

.step01 .icon-paw:before {
    content: '\e810';
}

.step02 i.icon-bone:before {
    content: '\e811';
}

.step03 i.icon-info:before {
    content: '\e808';
}

.step04 i.icon-upload:before {
    content: '\e806';
}

.filter-options input+label {
    display: none;
}

.filter-options input:checked+label {
    display: inline-block;
}

.user-info i.icon-person-pin:before {
    content: '\e80f';
}

.user-info i.icon-person-pin {
    background: #667D91;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: inline-block;
    padding: 0.5rem;
    font-size: 2rem;
}

.user-info i.icon-person-pin:before {
    content: '\e80f';
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
    line-height: 2.8;
    color: #fff;
}

.step01 .icon-paw:before, .user-info i.icon-person-pin:before, .step02 i.icon-bone:before, .step03 i.icon-info:before, .step04 i.icon-upload:before {
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
    line-height: 1.8;
    color: #fff;
}

.bg-draft {
    background-color: #667D91;
}

.bg-open {
    background-color: #773d67;
}

.bg-canceled {
    background-color: #FB291A;
}

.bg-in_progress {
    background-color: #FB8B1A;
}

.bg-completed {
    background-color: #eec306;
}

.bg-closed {
    background-color: #4BD63C;
}

.bg-invoice_send {
    background-color: #0d6efd;
}

.bg-archived {
    background-color: #454545;
}

#form_check_contrast_medium{
    position: absolute;
    top:0;
    right:0;
    width:auto;
}

#form_check_contrast_medium label{
    color: var(--bs-gray-600);
    line-height: 1.542;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
}

#dropArea.drop-hover{
    border:2px dashed var(--bs-gray-600);
    border-radius: 2px;
    background: var(--bs-gray-200);
}

@media (max-width: 1640px) {
    .katzen-img img {
        width: 690px;
        right: -4rem;
    }

    .dog-pic {
        bottom: -12rem;
    }

    .dog-pic img {
        width: 863px;
    }
}

@media (max-width: 1439px) {
    #navarea #hnav ul li {
        padding-right: 2.5rem;
    }

    .logged-in {
        right: 2rem;
    }

    .katzen-img img {
        width: 590px;
        right: -2rem;
        bottom: -3rem;
    }

    .message-info {
        font-size: 1rem;
    }
}

@media (max-width: 1368px) {
    .dog-pic {
        bottom: -4rem;
    }

    .dog-pic img {
        width: 740px;
    }
}

@media (max-width: 1199.98px) {
    h1 {
        font-size: 2.5rem;
    }

    .us-katze img {
        right: 0;
    }

    #navarea #hnav ul li a.active {
        border-top: none;
        color: #fff;
    }

    #navarea #hnav ul li:hover a, #hnav ul li:focus a, #hnav a.trail {
        border-top: none;
        background-color: #111921;
    }

    #navarea #hnav ul li a, #hnav ul li a.active {
        font-size: 1.3rem;
        font-weight: 700;
        color: #fff;
    }

    #navarea #hnav ul li {
        padding: 0;
    }

    #navarea #hnav ul li a {
        color: #fff;
        padding: 7px 14px 7px 14px;
    }

    #navarea #hnav ul li {
        display: block;
    }

    #navarea #hnav ul li:hover a, #hnav ul li:focus a, #hnav ul li a {
        color: #fff;
    }

    #navarea #hnav {
        position: fixed;
        display: none;
        z-index: 50;
        width: 50vw;
        height: 100vh;
        text-align: left;
        right: 0;
        top: 4.5rem;
        padding-top: 1rem;
        background: #02bec4;
    }

    #navarea #hnav.showMenu {
        display: block;
    }

    #navarea #hnav ul li a {
        display: block;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }

    #navarea #hnav ul li a.active {
        background-color: #111921;
        margin-left: 0;
        padding-left: 0.5rem;
        color: #fff;
    }

    #show {
        display: inline-block;
        background: none;
        border: none;
    }

    #show i:before {
        color: #02bec4;
        font-size: 2rem;
        line-height: 0.85;
        display: inline-block;
    }

    .container-blur {
        filter: opacity(0.5) blur(3px)
    }

    .body-overflow {
        overflow-y: hidden; 
    }


    .logged-in {
        right: 6rem;
        top: 1.5rem;
    }

    #navarea .logo {
        top: 1.3rem;
    }

    #navarea {
        height: 3.5rem;
    }

    .katzen-img img {
        position: relative;
        right: 0;
        bottom: 0;
        width: 300px;
    }

    .bg-footer {
        background-position: -18rem 3rem;
        background-size: 53rem;
        padding-top: 21rem;
    }

    .dog-pic {
        bottom: 2rem;
    }

    .dog-pic img {
        width: 668px;
    }

    .form-label, .form-check-label {
        font-size: 1rem;
    }
}

@media (max-width: 991.98px) {
    #navarea-small #hnav ul li a {
        font-size: 1.1rem;
    }

    #navarea-small #hnav ul li a {
        padding-right: 2.5rem;
    }

    .logo img {
        width: 38px;
    }

    .us-katze img {
        width: 338px;
        top: -4.5rem;
        right: -10rem;
    }

    .square-grey {
        height: 23rem;
    }

    .dog-pic {
        display: none;
    }

    .bg-footer {
        background-position: -15rem 2rem;
        background-size: 40rem;
        padding-top: 15rem;
    }

    .square-grey-left {
        width: 55%;
    }

    h2.font-green {
        font-size: 1.3rem;
    }

    .bg-grey-left {
        border-radius: 15px;
        margin-right: 0;
    }

    .bg-grey-right {
        border-radius: 15px;
        margin-left: 0;
    }

    #progressbar li {
        width: 33%;
    }

    li#step04, li#finished {
        margin-top: 1rem;
    }

    ul#progressbar {
        padding-left: 0;
    }

    .step02 i.icon-bone:before {
        line-height: 1.9;
    }

    .searchbar .form-control {
        width: 50%;
    }

    .table .table-title {
        font-size: 1.1rem;
    }

    .message small {
        font-size: 0.9rem;
        line-height: 1.2;
    }

}

@media (max-width: 767.98px) {
    .searchbar .form-control {
        width: 80%;
    }

    h1 {
        font-size: 1.7rem;
    }

    p {
        font-size: 1.1rem;
    }

    h3 {
        font-size: 1.3rem;
    }

    #topscroller a {
        font-size: 2rem;
        line-height: 2.7rem;
    }

    #topscroller {
        width: 3rem;
        height: 3rem;
    }

    .us-katze img {
        width: 244px;
        top: -0.5rem;
        right: -7rem;
    }

    #navarea #hnav {
        width: 100vw;
    }

    #hnav ul li a {
        padding-left: 30px !important;
    }

    .info-founder-text h4 {
        font-size: 1.2rem;
    }

    .filter-options input+label,
    a.white-rounded-link, button.btn-white, button.btn-green, a.green-rounded-link {
        font-size: 1.1rem;
    }

    #progressbar li {
        width: 50%;
    }

    li#step03 {
        margin-top: 1rem;
    }

    #progressbar #step01:before, #progressbar #step02:before, #progressbar #step03:before, #progressbar #step04:before {
        font-size: 1.8rem;
        line-height: 1.2;
    }

    #progressbar #finished:before {
        font-size: 1.5rem;
        line-height: 1.5;
    }

    #progressbar li:after {
        height: 6px;
        top: 18px;
    }

    #progressbar li:before {
        width: 40px;
        height: 40px;
    }

    h4 {
        font-size: 1.2rem;
    }

    .communication {
        overflow: scroll;
    }

    .progress {
        font-size: 0.9rem;
    }
}

@media (max-width: 575.98px) {
    #navarea-small #hnav ul {
        padding-left: 6rem;
    }

    .logged-in {
        right: 5rem;
    }

    .katzen-img img {
        width: 214px;
    }

    .bg-footer {
        background-position: -12rem 3rem;
        background-size: 34rem;
        padding-top: 15rem;
    }

    .info-founder img {
        width: 166px;
    }

    h2.font-green {
        font-size: 1.1rem;
    }

    a.green-rounded-link, a.white-rounded-link, button.btn-white {
        display: block;
        text-align: center;
    }

    .step01 i.icon-paw, .step02 i.icon-bone, .step03 i.icon-info, .step04 i.icon-upload {
        width: 2rem;
        height: 2rem;
    }

    .link-upload-cancel, .link-upload {
        font-size: 1rem;
    }
}

@media (max-width: 459.98px) {
    #navarea-small #hnav ul li a {
        font-size: 1rem;
    }

    #navarea-small #hnav ul li a {
        padding-right: 1.5rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    .info-founder img {
        width: 141px;
    }

    .info-founder-text h4 {
        font-size: 1.1rem;
    }

    .step01 i.icon-paw, .step02 i.icon-bone, .step03 i.icon-info, .step04 i.icon-upload {
        display: block;
        margin-bottom: 1rem;
    }
}

@media (max-width: 399.98px) {
    #navarea-small #hnav ul {
        padding-left: 4.5rem;
    }

    .bg-footer {
        background-position: -10rem 0rem;
        background-size: 28rem;
        padding-top: 9rem;
    }

    ul#progressbar li {
        font-size: 0.7rem;
    }
}

.placeholder-loading {
    background-color: #f0f0f09c;
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 20px;
}

.placeholder-loading:before {
    display: block;
    content: " ";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    top: 0;
    -webkit-animation: ph-animation .8s linear infinite;
    animation: ph-animation .8s linear infinite;
    background: linear-gradient(45deg, transparent, #ffffffd6, transparent);
    z-index: 1;
    pointer-events: none;
    box-sizing: border-box;
}

@-webkit-keyframes ph-animation {
    0% {
        left: -100%;
    }

    to {
        left: 200%
    }
}

@keyframes ph-animation {
    0% {
        left: -100%;
    }

    to {
        left: 200%
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #02bec4;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #02bec4 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes button-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@-webkit-keyframes button-rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.submitted {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    margin-top: 5px;
    margin-bottom: 0;
    margin-left: 10px;
    border: solid 2px black;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: button-rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
    animation-name: button-rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#message, #history {
    max-height: 500px;
    overflow: auto;
}

.link-upload .upload-status{
    display: 'block';
    position: absolute;
    left:0;
    bottom:0; 
    content: ' ';
    height: 5px;
    background: #773d67;
}

.select-files{
    border-radius: 5px;
    background: white;
    color: #773d67;
    border: 1px solid #773d67;
    padding: 2px 12px !important;
    cursor: pointer;
}

.select-files:hover{
    background: #773d67;
    color: white;
}